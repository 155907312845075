import { GetProcessesByAgentRequest, GetProcessesByAgentResponse } from "@/services/processes-by-agent/getProcessesByAgentService";

export function useProcessesByAgent() {
  const getProcessesByAgent = async (req: GetProcessesByAgentRequest, signal: AbortSignal) => {
    const result = await fetch(`/api/processes-by-agent/?id=${req.id}`, {
      method: "GET",
      signal
    });
    const { data } = await result.json();
    const resultJson = data.contenido as GetProcessesByAgentResponse;
    return resultJson;
  };
  return getProcessesByAgent;
}
