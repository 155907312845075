/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { useRouter } from "next/navigation";

// Components.
import { ColumnTable, RowTable } from "@/components/table/UITableProps";
import UIButton from "@/components/buttons/UIButton";
import UIPrivateLayout from "@/components/layouts/UIPrivateLayout";
import UIRightSidebarLayout from "@/components/layouts/sidebar/UIRightSidebarLayout";
import UITable from "@/components/table/UITable";
import { Box, styled } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import colors from "@/utils/colorsV2";

// Hooks
import { useProcessesByAgent } from "@/hooks/useProcessesByAgent";
import { useRequest } from "@/hooks/useRequest";
import { useInfoFiadoContext } from "@/context/infoFiadoContext";
import UIModal from "@/components/modal/UIModal";
import { enqueueSnackbar } from "notistack";
import { useCurrentSessionContext } from "@/context/currentSessionContext";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import UIText from "@/components/text/UIText";
import SearchBox from "@/components/SearchBox";
import UILoading from "@/components/loading/UILoading";
import { Action } from "@/components/menu/menu-actions/UIMenuActions";
import Status, { statusManualSigned } from "@/utils/processStatus";
import UIContentModal from "@/components/modal/content-mis-solicitudes/UIContentModal";
import { useComparisionManagement } from "@/hooks/useComparisonManagement";
import { DuplicateComparasonRequest } from "@/services/duplicate-comparision/duplicateComparisonService";
import { useModal } from "mui-modal-provider";
import UIConfirmationDialog from "@/components/dialogs/UIConfirmationDialog";
import UIDuplicateRegistrationModal from "@/components/dialogs/UIDuplicateRegistrationModal";
import { CompanyName, isAserta } from "@/utils/companies";
import { isManualSigned } from "@/utils/functions";

const columnsTable: ColumnTable[] = [
  { id: "idSolicitud", label: "ID solicitud ASI" },
  { id: "nombre", label: "Cliente" },
  { id: "rfc", label: "RFC" },
  { id: "estatus", label: "Estatus" },
  { id: "fechaAlta", label: "Fecha de inicio de alta" },
  { id: "empresa", label: "Aseguradora" },
  { id: "actions", label: "Acciones" },
  { id: "idEstatus", label: "idEstatus" }
];

const breadcrumbsOptions = [
  {
    icon: <HomeIcon sx={{ fontSize: "20px", color: "#566680" }} />,
  },
  {
    label: "Mis solicitudes",
  },
];

const statusByAction = {
  edit: [
    Status.VERIFICATION
  ],
  view: [
    Status.IN_SIGNING,
    Status.CONTRACT_REVIEW,
    Status.GUARANTOR_CONTRACT_REVIEW,
    Status.CONTRACT_VALIDATION_REJECT,
  ],
  duplicate: [
    Status.REGISTRATION_COMPLETE
  ],
  cancel: [
    Status.DUPLICATE_GUARANTOR,
    Status.INVALID_GUARANTOR,
    Status.REGISTRATION_COMPLETE,
    Status.ERROR_LEGACY,
  ],
  retry: [
    Status.GUARANTOR_VALIDATION_ERROR,
    Status.CREATING_REQUEST_ERROR,
    Status.ERROR_GUARANTOR_CONTRACT_REVIEW,
    Status.ERROR_IN_SIGNING,
    Status.UPDATING_REQUEST_ERROR,
    Status.ERROR_AGENT_CHANGE_REQUEST,
    Status.ERROR_GUARANTOR_CHANGE_REQUEST,
  ],
}

const ContaineDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  width: "100%",
  height: "100vh",
  padding: "32px",
});

interface ModalProperties {
  open: boolean;
  title: string;
  type: 'view' | 'duplicate' | 'cancel';
  isFad?: boolean;
  idSolicitud: string;
}

function Home() {
  const { showModal } = useModal();
  const { data, setData, idProcessFiado, setIdProcessFiado } = useInfoFiadoContext();
  const [isLoading, setIsLoading] = useState(true);
  const [messageLoading, setMessageLoading] = useState("Loading...");
  const [tempSearch, setTempSearch] = useState("");
  const [search, setSearch] = useState("");
  const [request, setRequests] = useState<RowTable[]>([]);
  const { session } = useCurrentSessionContext();
  const [agent, setAgent] = useState<string>(session?.user?.login ?? "");
  const [filterData, setFilterData] = useState<RowTable[]>([]);
  const [company, setCompany] = useState<string>("");
  const [next, setNext] = useState<boolean>(false);
  const [modalProperties, setModalProperties] = useState<ModalProperties>({
    open: false,
    title: "",
    type: "view",
    idSolicitud: ""
  });

  const { push } = useRouter();
  const { retryComparison, cancelComparison, duplicateComparison } = useComparisionManagement();
  const { getInfoFiado } = useRequest();

  useEffect(() => {
    if (data !== undefined && next) { //espera a que data este disponible para continuar a la pantalla de la constancia
      stepInProcess(idProcessFiado);
    }
  }, [data, next]);

  useEffect(() => {
    if (!session) {
      return;
    }

    if (session.user?.login === undefined || session.user?.login === '') {
      return;
    }

    if (agent === session.user.login) {
      return;
    }

    setAgent(session.user.login);
  }, [session]);

  useEffect(() => {
    let dataFiltered = request;
    dataFiltered?.sort((a, b) => {
      const dateA = new Date(a.fechaAlta);
      const dateB = new Date(b.fechaAlta);
      return dateB.getTime() - dateA.getTime();
    });

    if (search !== "") {
      dataFiltered = dataFiltered.filter((item) =>
        Object.values(item).some((value) =>
          value.toString().toLowerCase().includes(search.toLowerCase())
        )
      );
    }

    setFilterData(dataFiltered);
  }, [search, request]);

  const processByAgent = useProcessesByAgent();
  const getList = useCallback(async (agente: string) => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setIsLoading(true);
    setMessageLoading("Buscando solicitudes...");

    processByAgent({ id: agente }, signal)
      .then((response) => {
        const withStatus = response && response.filter((item) => item.estatus || item.idEstatus);
        setRequests(withStatus);
      })
      .catch((error) => {
        console.log("error", error);
        if (error.name !== "AbortError") {
          enqueueSnackbar(error.message, {
            variant: "error",
            preventDuplicate: true,
          });
        }
      })
      .finally(() => setIsLoading(false));

    return () => {
      abortController.abort(); // Cancela la solicitud anterior 
    };

  }, []);

  useEffect(() => {
    if (!agent) return;

    getList(agent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent]);

  const stepInProcess = (id: string) => {
    push(`/create/tax-situation?idProcess=${id}&company=${company}`);
  };

  const handleOnClickSearch = () => {
    setSearch(tempSearch);
  };

  const viewHandler = async (solicitud: RowTable) => {
    setModalProperties({
      open: true,
      title: "",
      type: "view",
      isFad: !isManualSigned(solicitud.idEstatus),
      idSolicitud: solicitud.idSolicitud.toString()
    });
  }

  const editHandler = async (solicitud: RowTable) => {
    setIsLoading(true);
    setMessageLoading("Buscando solicitud...");
    setCompany(solicitud.empresa.toString());

    const id = solicitud.idSolicitud.toString();
    getInfoFiado(id)
      .then((response) => {
        setMessageLoading("Solicitud encontrada");
        setIdProcessFiado(id);
        setData(response);
        setNext(true);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const retryHandler = async (solicitud: RowTable) => {
    setIsLoading(true);
    setMessageLoading("Reintentando acciones fallidas...");

    const id = solicitud.idSolicitud.toString();
    retryComparison({ idProceso: id })
      .then((response) => {
        setMessageLoading("Solicitud reenviada");
        console.log("response", response);
        getList(agent);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
          preventDuplicate: true,
        });
        console.log("error", error);
        setIsLoading(false);
      })
  }

  const duplicateHandler = async (solicitud: RowTable) => {
    console.log("solicitud", solicitud);
    const companyName = isAserta(solicitud.empresa?.toString()) ? CompanyName.INSURGENTES : CompanyName.ASERTA;
    const modal = showModal(
      UIDuplicateRegistrationModal,
      {
        onAccept: () => {
          setIsLoading(true);
          setTimeout(async () => {
            await getList(agent);
          }, 1000);
         },
        processId: solicitud.idSolicitud.toString(),
        companyName: companyName,
      }
    );
  }

  const cancelHandler = async (solicitud: RowTable) => {
    setModalProperties({
      open: true,
      title: "",
      type: "cancel",
      idSolicitud: solicitud.idSolicitud.toString()
    });
  }

  const isValidStatus = (idStatus: string | number, statuses: string[]) => {
    if (!idStatus){
      return false;
    }

    const id = idStatus.toString();
    if (!id || id === "") {
      return false;
    }

    return statuses.includes(id);
  }

  const actions: Action[] = [
    {
      label: "Ver solicitud",
      onClick: viewHandler,
      icon: "/assets/icons/eye.svg",
      disabled: (solicitud: RowTable) => {
        try {
          return !isValidStatus(solicitud.idEstatus, statusByAction.view);
        } catch (error) {
          return true;
        }
      },
      default: true
    },
    {
      label: "Editar solicitud",
      onClick: editHandler,
      icon: "/assets/icons/edit.svg",
      disabled: (solicitud: RowTable) => {
        return !isValidStatus(solicitud.idEstatus, statusByAction.edit);
      },
    },
    {
      label: "Reintentar proceso fallido",
      onClick: retryHandler,
      icon: "/assets/icons/edit.svg",
      disabled: (solicitud: RowTable) => {
        return !isValidStatus(solicitud.idEstatus, statusByAction.retry);
      },
    },
    {
      label: "Replicar solicitud",
      onClick: duplicateHandler,
      icon: "/assets/icons/copy.svg",
      disabled: (solicitud: RowTable) => {
        return !isValidStatus(solicitud.idEstatus, statusByAction.duplicate);
      },
    },
    {
      label: "Eliminar solicitud",
      onClick: cancelHandler,
      icon: "/assets/icons/trash-01.svg",
      disabled: (solicitud: RowTable) => {
        try {
          //Valida si es un estado terminal, si es así no se puede cancelar
          if (isValidStatus(solicitud.idEstatus, statusByAction.cancel)) {
            throw new Error("Invalid status");
          }

          const insurgentes = solicitud.idSiebelInsurgentes ?? "";
          const aserta = solicitud.idSiebelAserta ?? "";

          return insurgentes !== "" || aserta !== "";
        } catch (error) {
          return true;
        }
      },
    }
  ]

  const handleCancel = async (idSolicitud: string) => {
    try {
      setIsLoading(true);
      setModalProperties((prev) => ({ ...prev, open: false }));
      setMessageLoading("Cancelando solicitud...");

      const response = await cancelComparison({ idProcess: idSolicitud })
      console.log("response", response);
      if (!response || response !== "OK") {
        throw new Error("No fue posible cancelar la solicitud");
      }
      
      await getList(agent);
    } catch (error: any) {
      console.log("error", error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  const pushHandler = async (rute: string) => {
    push(rute);
  }

  const closeModal = () => {
    setModalProperties({ open: false, title: "", type: "view", idSolicitud: "" })
  }

  return (
    <UIPrivateLayout>
      <UIRightSidebarLayout
        isLoading={isLoading}
        messageLoading={messageLoading}
        open={true}
        drawerWidth={240}
      >
        <ContaineDiv>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Breadcrumbs options={breadcrumbsOptions} />
          </Box>
          <Box
            sx={{
              border: `1px solid ${colors.grayLM200}`,
              borderRadius: "12px",
              backgroundColor: "white",
              position: "relative",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                padding: "20px",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <UIText
                text={"Clientes"}
                size="text-lg"
                color="blue"
                weight="semibold"
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SearchBox
                  label="Buscar"
                  value={tempSearch}
                  onChange={setTempSearch}
                  onClickSearch={handleOnClickSearch}
                />
                <UIButton
                  sx={{ marginLeft: "80px" }}
                  label="Nuevo cliente"
                  color="orange"
                  size="full"
                  startIcon={<PersonAddIcon />}
                  onClick={() => push("/registration")}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                position: "relative",
                height: "100%",
                flexDirection: "row",
              }}
            >
              <UITable
                columns={columnsTable}
                rows={filterData}
                actions={actions}
              />
            </Box>
          </Box>
          <UIModal
            open={modalProperties.open}
            onClose={closeModal}
            logo={"/assets/icons/face.svg"}
            size={{ height: "auto", width: "" }}
            titleContent={modalProperties.title}
            bodyStyles={{ height: "auto" }}
            body={
              <UIContentModal
                type={modalProperties.type}
                idSolicitud={modalProperties.idSolicitud}
                isFad={modalProperties.isFad}
                onCanceled={handleCancel}
                pushed={pushHandler}
                close={closeModal}
              />
            }
          />
        </ContaineDiv>
      </UIRightSidebarLayout>
    </UIPrivateLayout>
  );
}

const PageWrapper = () => (
  <Suspense fallback={<UILoading />}>
    <Home />
  </Suspense>
);

export default PageWrapper;
